<template>
  <div class="">
    <!--begin::Header-->
    <div class="row m-0">
      <div class="col-8 p-0 bg-white model_by_ch_top">
        <div class="py-4 px-4 border_b_silver">
          <span class="card-label font-weight-bolder Main_Blue"
            >Job applications</span
          >
        </div>

        <div data-app class="row m-0 col-12 p-0">
          <div class="col-md-12 row m-0">
            <!-- {{ data }} -->
            <div v-if="data.length === 0">
              <p colspan="4" style="text-align: center">No data found</p>
            </div>
            <div
              class="col-md-12 row m-0 border____ch bg-white mb-3"
              :search="search"
              v-for="(item, index) in data"
              :key="index"
            >
              <div class="col-10 p-0 row m-0">
                <div class="col-12 px-0 py-1 row m-0 border___ch">
                  <div class="col-1 p-0">
                    <v-img
                      max-height="40"
                      max-width="40"
                      height="40"
                      :src="mediaUrl + item.recruiter_profile.profile_pic"
                    ></v-img>
                  </div>
                  <div class="col-5 py-0 pr-0">
                    <div>
                      <div style="line-height: 6px">
                        <h4
                          class="mb-0"
                          style="
                            color: #337ab7;
                            font-size: 14px;
                            font-weight: 600;
                          "
                        >
                          {{ item.recruiter_profile.company_name }}
                        </h4>
                        <p
                          class="m-0"
                          style="color: #777; font-size: 12px"
                          v-if="item.recruiter_profile.office_phone_1"
                        >
                          ({{ item.recruiter_profile.office_phone_1 }})
                        </p>
                        <p
                          class="m-0"
                          style="color: #777; font-size: 12px"
                          v-if="!item.recruiter_profile.office_phone_1"
                        >
                          ( no add number )
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col p-0 Main_Blue" style="font-size: 14px">
                    {{ item.job.job_title }}
                  </div>
                  <div class="col pb-0 pl-0 pt-2">
                    <div
                      class="Main_Blue"
                      style="
                        font-size: 14px;
                        padding: 2px;
                        text-align: center;
                        background: #b9e5fb7d;
                      "
                    >
                      {{ item.status }}
                    </div>
                  </div>
                </div>
                <div class="col-12 pr-0 pt-4 pb-0">
                  <div class="d-flex float-right m-0">
                    <div
                      class="py-0 px-2"
                      style="text-align: end"
                      @click="downloadCV(item.cv_path)"
                    >
                      <button
                        type="button"
                        class="
                          custom-add-new-record-button
                          btn_dash_ch__gray
                          v-btn v-btn--is-elevated v-btn--has-bg
                          theme--light
                          btn_span_ch
                          v-size--default
                        "
                      >
                        <span class="v-btn__content">View CV</span>
                      </button>
                    </div>
                    <div
                      class="p-0 pr-2"
                      @click="downloadCV(item.cv_path)"
                      style=""
                    >
                      <button
                        type="button"
                        class="
                          custom-add-new-record-button
                          btn_dash_ch__gray
                          v-btn v-btn--is-elevated v-btn--has-bg
                          theme--light
                          btn_span_ch
                          v-size--default
                        "
                      >
                        <span class="v-btn__content">Download CV</span>
                      </button>
                    </div>
                    <div
                      class="p-0 pr-2"
                      @click="sendMsgPopupHandler(item)"
                      style=""
                    >
                      <button
                        type="button"
                        class="
                          custom-add-new-record-button
                          btn_dash_ch__gray
                          v-btn v-btn--is-elevated v-btn--has-bg
                          theme--light
                          btn_span_ch
                          v-size--default
                        "
                      >
                        <span class="v-btn__content">Send Mail</span>
                      </button>
                    </div>
                    <div
                      class="p-0 pr-0"
                      @click="askUserAboutWithdraw(item)"
                      style="text-align: end"
                    >
                      <button
                        type="button"
                        class="
                          custom-add-new-record-button
                          btn_red__ch
                          v-btn v-btn--is-elevated v-btn--has-bg
                          theme--light
                          v-size--default
                        "
                      >
                        <img
                          src="\images/job_application/Delete.svg"
                          alt=""
                          class="dashbord"
                          width="16"
                          height="16"
                        />
                        <span class="v-btn__content pl-1">Delete</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2 row m-0 p-0 pl-2">
                <div class="col-6 p-0 text-center">
                  <button
                    v-on:click="singleDetail1(item)"
                    type="button"
                    class="btn_dash_ch__ btn_span_ch"
                  >
                    <img
                      src="\images/job_application/Video_icon_.svg"
                      alt=""
                      class="dashbord25"
                      width="25"
                      height="25"
                    />
                  </button>
                </div>
                <div class="col-6 p-0 text-center">
                  <button
                    v-on:click="singleDetail2(item)"
                    type="button"
                    class="btn_dash_ch__ btn_span_ch"
                  >
                    <img
                      src="\images/job_application/Audio_icon_normal.svg"
                      alt=""
                      class="dashbord25"
                      width="25"
                      height="25"
                    />
                  </button>
                </div>
                <div class="col-6 p-0 text-center mt-2">
                  <button
                    v-on:click="singleDetail3(item)"
                    type="button"
                    class="btn_dash_ch__ btn_span_ch"
                  >
                    <img
                      src="\images/job_application/Conversations_icon_normal.svg"
                      alt=""
                      class="dashbord25"
                      width="25"
                      height="25"
                    />
                  </button>
                </div>
                <div class="col-6 p-0 text-center mt-2">
                  <button
                    v-on:click="singleDetail4(item)"
                    type="button"
                    class="btn_dash_ch__ btn_span_ch"
                  >
                    <img
                      src="\images/job_application/Q&A_icon_normal.svg"
                      alt=""
                      class="dashbord25"
                      width="25"
                      height="25"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 p-0 sidbar_ch_new2" v-if="detail1">
        <div class="col-lg-12 py-0">
          <div class="card sidebar">
            <div class="card-body widget border-bottom cardbodych" style="">
              <h5
                class="
                  card-label
                  font-weight-bolder
                  Main_Blue
                  position_style_ch
                  border-0
                "
              >
                Video introduction
              </h5>
              <button
                class="badge badge-danger badge-pill"
                @click="detail1 = false"
              >
                X
              </button>
            </div>
            <div class="col-12 bg-white">
              <video-player
                class="video-player-box video_by_ch"
                ref="videoPlayer"
                :options="detail1.videoPlayer"
                :playsinline="true"
                customEventName="customstatechangedeventname"
              >
              </video-player>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <div class="col-lg-4 p-0 sidbar_ch_new2" v-if="detail2">
        <div class="col-lg-12 py-0">
          <div class="card sidebar">
            <div class="card-body widget border-bottom cardbodych" style="">
              <h5
                class="
                  card-label
                  font-weight-bolder
                  Main_Blue
                  position_style_ch
                  border-0
                "
              >
                Audio introduction
              </h5>
              <button
                class="badge badge-danger badge-pill"
                @click="detail2 = false"
              >
                X
              </button>
            </div>
            <div class="col-12 bg-white">
              <video-player
                class="video-player-box video_by_ch"
                ref="videoPlayer1"
                :options="detail2.videoPlayer1"
                :playsinline="true"
                customEventName="customstatechangedeventname"
              >
              </video-player>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <div class="col-lg-4 p-0 sidbar_ch_new2" v-if="detail3">
        <div class="col-lg-12 py-0">
          <div class="card sidebar">
            <div class="card-body widget border-bottom cardbodych" style="">
              <h5
                class="
                  card-label
                  font-weight-bolder
                  Main_Blue
                  position_style_ch
                  border-0
                "
              >
                Massges
              </h5>
              <button
                class="badge badge-danger badge-pill"
                @click="detail3 = false"
              >
                X
              </button>
            </div>
            <div class="card-body widget border-bottom cardbodych px-4 py-2">
              <p
                style="
                  color: rgb(51, 122, 183);
                  font-size: 14px;
                  font-weight: 600;
                "
                class="d-flex m-0 p-0"
              >
                {{ detail3.recruiter_profile.company_name }}
                <span
                  class="pl-1"
                  style="color: rgb(119, 119, 119); font-size: 12px"
                >
                  ({{ detail3.job.job_title }})</span
                >
              </p>
            </div>
            <div class="col-12 bg-white cardbodych3">
              <!-- {{ detail3 }} -->
              <div
                v-for="(item, i) in conversation"
                :key="i"
                style="margin-left: 3%; max-width: 660px"
              >
                <!-- {{ item }} -->
                <!-- <p>message: {{ item.messages[0].message }}</p> -->

                <div v-for="(_item, _i) in item.messages" :key="_i">
                  <!-- {{ _item }} -->
                  <div style="display: flex">
                    <div>
                      <img
                        :src="mediaUrl + detail3.recruiter_profile.profile_pic"
                        width="23"
                        v-if="user.id !== _item.sender_id"
                      />
                    </div>
                    <p style="margin-left: 2%">
                      {{
                        user.id == _item.sender_id
                          ? "Me :"
                          : detail3.recruiter.name
                      }}
                    </p>
                  </div>
                  <p>Subject: {{ _item.message_title }}</p>
                  <p class="d-flex">
                    Message: <span class="pl-1" v-html="_item.message"></span>
                  </p>
                  <p>
                    {{ moment(_item.created_at).format("LLL") }}
                  </p>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <div class="col-lg-4 p-0 sidbar_ch_new2" v-if="detail4">
        <div class="col-lg-12 py-0">
          <div class="card sidebar">
            <div class="card-body widget border-bottom cardbodych" style="">
              <h5
                class="
                  card-label
                  font-weight-bolder
                  Main_Blue
                  position_style_ch
                  border-0
                "
              >
                Answer Questions
              </h5>
              <button
                class="badge badge-danger badge-pill"
                @click="detail4 = false"
              >
                X
              </button>
            </div>
            <div class="col-12 bg-white cardbodych3">
              <div
                v-for="(detail4, i) in detail4.answered_questions"
                :key="i"
                style="margin-left: 3%; max-width: 660px"
              >
                <span
                  ><b>Question #{{ i + 1 }}:</b> {{ detail4.question }}</span
                ><br />
                <span v-if="detail4.type == 'multi'">
                  <b> Aanswer:</b>
                  {{ JSON.parse(detail4.answer).toString() }}
                </span>
                <span v-if="detail4.type != 'multi'">
                  <b> Answer:</b> {{ detail4.answer }} </span
                ><br /><br />
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
    </div>
    <!-- <div class="px-4 d-none" data-app>
      <div class="row">
        <div class="col-md-12">
          <v-card class="dashbord_job_applications">
            <v-data-table
              :headers="headers"
              :items="data"
              :search="search"
              item-key="recruiter.name"
              class="elevation-1 headers_job_function_ch"
            >
              <template v-slot:item="props">
                <tr class="border__ch">
                  <td style="width: 25%">
                    <div style="display: flex; padding: 1%">
                      <v-img
                        max-height="40"
                        max-width="40"
                        :src="
                          mediaUrl + props.item.recruiter_profile.profile_pic
                        "
                        style="border-radius: 0px; margin-top: 1%"
                      ></v-img>
                      <div
                        style="
                          margin-left: 6%;
                          line-height: 6px;
                          margin-top: 1%;
                        "
                      >
                        <p
                          class="m-0 Main_Blue"
                          style="font-size: 14px; font-weight: 600"
                        >
                          {{ props.item.recruiter_profile.company_name }}
                        </p>

                        <p class="m-0" style="color: #777; font-size: 12px">
                          ({{ props.item.recruiter_profile.office_phone_1 }})
                        </p>
                      </div>
                    </div>
                  </td>
                  <td class="Main_Blue" style="font-size: 14px">
                    {{ props.item.job.job_title }}
                  </td>
                  <td class="Main_Blue" style="font-size: 14px">
                    {{ props.item.status }}
                  </td>
                  <td style="position: absolute; right: -14px; top: 9px">
                    <tr>
                      <td
                        class="py-0 px-2"
                        style="text-align: end"
                        @click="downloadCV(props.item.cv_path)"
                      >
                        <button
                          type="button"
                          class="
                            custom-add-new-record-button
                            btn_dash_ch
                            v-btn v-btn--is-elevated v-btn--has-bg
                            theme--light
                            v-size--default
                          "
                        >
                          <span class="v-btn__content">View</span>
                        </button>
                      </td>
                      <td
                        class="p-0 pr-2"
                        @click="sendMsgPopupHandler(props.item)"
                        style=""
                      >
                        <button
                          type="button"
                          class="
                            custom-add-new-record-button
                            btn_dash_ch
                            v-btn v-btn--is-elevated v-btn--has-bg
                            theme--light
                            v-size--default
                          "
                        >
                          <span class="v-btn__content">Send Message</span>
                        </button>
                      </td>
                      <td
                        class="p-0 pr-4"
                        @click="downloadCV(props.item.cv_path)"
                        style=""
                      >
                        <button
                          type="button"
                          class="
                            custom-add-new-record-button
                            btn_dash_ch
                            v-btn v-btn--is-elevated v-btn--has-bg
                            theme--light
                            v-size--default
                          "
                        >
                          <span class="v-btn__content">Download</span>
                        </button>
                      </td>
                      <td
                        class="p-0 pr-3"
                        @click="askUserAboutDelete(props.item)"
                        style="text-align: end"
                      >
                        <img
                          src="\images/job_application/Delete.svg"
                          alt=""
                          class="dashbord"
                          width="16"
                          height="16"
                        />
                      </td>
                    </tr>
                  </td>
                </tr>

                <tr class="border-0">
                  <td
                    class="tb_border__ch"
                    colspan="1"
                    style="padding: 0; vertical-align: top"
                  >
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          Answered questions
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div
                            v-for="(item, i) in props.item.answered_questions"
                            :key="i"
                            style="margin-left: 3%; max-width: 660px"
                          >
                            <span
                              ><b>Question #{{ i + 1 }}:</b>
                              {{ item.question }}</span
                            ><br />
                            <span v-if="item.type == 'multi'">
                              <b> Aanswer:</b>
                              {{ JSON.parse(item.answer).toString() }}
                            </span>
                            <span v-if="item.type != 'multi'">
                              <b> Answer:</b> {{ item.answer }} </span
                            ><br /><br />
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </td>

                  <td
                    class="tb_border__ch"
                    colspan="2"
                    style="padding: 0; vertical-align: top"
                  >
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          Intro video
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <video-player
                            class="video-player-box"
                            ref="videoPlayer"
                            :options="props.item.videoPlayer"
                            :playsinline="true"
                            customEventName="customstatechangedeventname"
                          >
                          </video-player>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </td>
                  <td
                    class="tb_border__ch"
                    colspan="2"
                    style="padding: 0; vertical-align: top"
                  >
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          Intro Audio
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <video-player
                            class="video-player-box"
                            ref="videoPlayer1"
                            :options="props.item.videoPlayer1"
                            :playsinline="true"
                            customEventName="customstatechangedeventname"
                          >
                          </video-player>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </td>
                  <td
                    class="tb_border__ch"
                    colspan="5"
                    style="padding: 0; vertical-align: top"
                  >
                    <v-expansion-panels>
                      <v-expansion-panel
                        @click="getConversation($event, props.item)"
                      >
                        <v-expansion-panel-header>
                          Conversation
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div
                            v-for="(item, i) in conversation"
                            :key="i"
                            style="margin-left: 3%; max-width: 660px"
                          >
                            <div v-for="(_item, _i) in item.messages" :key="_i">
                              <div style="display: flex">
                                <div>
                                  <img
                                    :src="
                                      mediaUrl +
                                      props.item.recruiter_profile.profile_pic
                                    "
                                    width="23"
                                    v-if="user.id !== _item.sender_id"
                                  />
                                </div>

                                <div>
                                  <img
                                    :src="mediaUrl + profile.profile_pic"
                                    width="23"
                                    v-if="user.id == _item.sender_id"
                                  />
                                </div>
                                <p style="margin-left: 2%">
                                  {{
                                    user.id == _item.sender_id
                                      ? "You: "
                                      : props.item.recruiter.name
                                  }}
                                </p>
                              </div>
                              <p>Subject: {{ _item.message_title }}</p>
                              Message:
                              <p v-html="_item.message"></p>
                              <p>
                                {{ moment(_item.created_at).format("LLL") }}
                              </p>
                              <hr />
                            </div>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </td>
                </tr>
                <tr>
                  <td style="height: 20px" colspan="12"></td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>

      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="380px">
          <v-card>
            <v-card-title>
              <span class="headline"
                >Do you really want to withdraw your request?</span
              >
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="custom-submit-button" text @click="dialog = false"
                >No</v-btn
              >
              <v-btn class="custom-cancel-button" text @click="deleteItem"
                ><b-spinner small type="grow" v-if="deleting"></b-spinner
                >Withdraw Request</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div> -->
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="380px">
        <v-card>
          <v-card-title>
            <span class="headline"
              >Do you really want to withdraw your request?</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-submit-button" text @click="dialog = false"
              >No</v-btn
            >
            <v-btn class="custom-cancel-button" text @click="deleteItem"
              ><b-spinner small type="grow" v-if="deleting"></b-spinner>Withdraw
              Request</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row class="model_by_ch" justify="center">
      <v-dialog
        v-model="sendMsgPopup"
        persistent
        max-width="600px"
        style="overflow-x: hidden"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Send direct message</span>
          </v-card-title>
          <div class="row" style="padding-left: 6%; padding-right: 6%">
            <div class="col-md-12">
              <v-text-field
                v-model="message_title"
                label="Subject"
              ></v-text-field>

              <v-file-input
                style="font-size: 8px"
                label="file"
                @change="Preview_image"
                v-model="file"
              >
              </v-file-input>
            </div>

            <label>Message</label>
            <vue-editor
              style="width: 100%; height: 80%"
              v-model="message"
            ></vue-editor>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              class="custom-submit-button"
              text
              @click="sendMsgPopup = false"
              >Cancel</v-btn
            >
            <v-btn class="custom-cancel-button" text @click="sendMsg"
              ><b-spinner small type="grow" v-if="deleting"></b-spinner
              >Send</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import Swal from "sweetalert2";
import JobSeekerService from "@/MainServices/JobSeekerService.js";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import { VueEditor } from "vue2-editor";
import moment from "moment";

export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      profile: JSON.parse(localStorage.getItem("profile")),
      mediaUrl: MEDIA_URL,
      statusItems: [
        "choose",
        "Applied",
        "Shorlisted",
        "Call For Interview",
        "Interviewd",
        "Selected",
      ],
      filter: "",
      search: "",
      loading: true,
      dialog: false,
      deleteId: null,
      deleting: false,
      success: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,

      headers: [
        { text: "COMPANY", value: "name" },
        { text: "JOB TITLE", value: "title" },
        { text: "STATUS", value: "status" },
        { text: "ACTIONS", value: "action" },
      ],
      data: [],
      originalData: [],
      bulkActionArr: [],
      activeVideo: "",
      sendMsgPopup: false,
      msgUser: null,
      message_title: "",
      message: "",
      file: null,
      file_url: null,
      jobId: null,
      conversation: [],
      detail1: null,
      detail2: null,
      detail3: null,
      detail4: null,
    };
  },
  mounted() {
    this.getInititalData();
  },
  components: {
    videoPlayer,
    VueEditor,
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
    moment: function (item) {
      if (item) {
        return moment(String(item));
      }
    },
    getInititalData() {
      JobSeekerService.getJobApplications()
        .then((res) => {
          this.loading = false;
          var temp = [];
          res.data.forEach((element) => {
            element.videoPlayer1 = {
              // videojs options
              muted: true,
              language: "en",
              playbackRates: [0.7, 1.0, 1.5, 2.0],
              sources: [
                {
                  type: "audio/wav",
                  src: this.mediaUrl + "videos/raw/" + element.intro_audio,
                },
              ],
              poster: "/static/images/author.jpg",
            };
            element.videoPlayer = {
              // videojs options
              muted: true,
              language: "en",
              playbackRates: [0.7, 1.0, 1.5, 2.0],
              sources: [
                {
                  type: "video/mp4",
                  src:
                    this.mediaUrl + "/videos/optimized/" + element.intro_video,
                },
              ],
              poster: "/static/images/author.jpg",
            };
            // temp.push(element);
            temp.push(element);
          });
          this.data = temp;
          this.originalData = temp;
          // console.log("data: ", this.data);
        })
        .catch(() => {
          this.loading = false;
        });
    },

    favoriteClick(id) {
      RecruiterService.changeJobApplicationFavoriteStatus(id)
        .then((res) => {
          Swal.fire({
            title: "",
            text: res.data,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    singleDetail1(item) {
      this.detail1 = item;
      this.detail2 = false;
      this.detail3 = false;
      this.detail4 = false;
    },
    singleDetail2(item) {
      this.detail2 = item;
      this.detail1 = false;
      this.detail3 = false;
      this.detail4 = false;
    },
    singleDetail3(item) {
      this.detail3 = item;

      // console.log(this.detail3);
      this.getConversation(item);
      this.detail2 = false;
      this.detail1 = false;
      this.detail4 = false;
    },
    singleDetail4(item) {
      this.detail4 = item;
      this.detail2 = false;
      this.detail3 = false;
      this.detail1 = false;
    },
    filterData() {
      var temp = [];
      var val = this.filter;
      if (val == "choose") {
        this.data = this.originalData;
      } else {
        this.originalData.forEach((element) => {
          if (element.status == val) {
            temp.push(element);
          }
        });
        this.data = temp;
      }
    },

    bulkAction(event, id) {
      var newArr = [];
      if (event == true) {
        newArr.push(id);
        this.bulkActionArr = newArr;
      } else {
        newArr = this.bulkActionArr.filter((item) => {
          return item !== id;
        });
        this.bulkActionArr = newArr;
      }
    },

    bulkActionProceed(val) {
      if (val !== "choose") {
        RecruiterService.changeJobApplicationStatus({
          ids: this.bulkActionArr,
          status: val,
        })
          .then((res) => {
            Swal.fire({
              title: "",
              text: res.data,
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
            this.getInititalData();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    downloadCV(path) {
      window.open(this.mediaUrl + path);
    },

    withdrawApplication(id) {
      JobSeekerService.withdrawJobApplication(id)
        .then((res) => {
          Swal.fire({
            title: "",
            text: res.data,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.dialog = false;
          this.loading = false;
          this.success = true;
          this.submitting = false;
          this.getInititalData();
        })
        .catch(() => {
          this.loading = false;
        });
    },

    askUserAboutWithdraw(item) {
      // console.log("id umer", item.id);
      this.deleteId = item.id;
      this.dialog = true;
    },

    deleteItem() {
      this.withdrawApplication(this.deleteId);
    },

    Preview_image() {
      this.file_url = this.file;
    },

    sendMsgPopupHandler(item) {
      this.sendMsgPopup = true;
      this.msgUser = item.recruiter_id;
      this.jobId = item.job_id;
      this.job_app_id = item.id;
    },

    resetSendMsg() {
      this.msgUser = null;
      this.message_title = "";
      this.message = "";
      this.file = null;
      this.file_url = null;
      this.sendMsgPopup = false;
      this.jobId = null;
    },

    sendMsg() {
      let formData = new FormData();
      formData.append("message_title", this.message_title);
      formData.append("job_id", this.jobId);
      formData.append("message", this.message);
      formData.append("receiver_id", this.msgUser);
      formData.append("file", this.file_url);
      formData.append("job_app_id", this.job_app_id);

      axios.defaults.headers.common["Authorization"] = this.token;
      axios.post(`${API_URL}/send-message`, formData).then((res) => {
        Swal.fire({
          title: "",
          text: res.data,
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.getInititalData();
        this.resetSendMsg();
      });
    },
    getConversation(item) {
      this.conversation = [];
      // if (event.target.classList.contains("v-expansion-panel-header--active")) {
      //   // console.log("Panel is closed.");
      // } else {
      JobSeekerService.getConversations(item.job_id).then((res) => {
        this.conversation = res.data;
      });
      // }
    },
  },
};
</script>
